@import '../../styles/colors.sass';

.button {
  position: relative;
  font-family: Roboto-medium, sans-serif;
  display: inline-flex;
  align-items: center;
  color: $white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;

  &--size-small {
    height: 25px;
    font-size: 12px;
    padding: 0 10px; }

  &--size-default {
    height: 32px;
    font-size: 15px;
    padding: 0 17px; }

  &--size-large {
    height: 39px;
    font-size: 18px;
    padding: 0 24px; }

  &--primary {
    background: $primary;
    &:hover {
      background: darken($primary, 8%); }
    &:active {
      background: darken($primary, 13%); }
    &:disabled {
      background: lighten($primary, 15%);
      cursor: not-allowed;
      color: lighten($primary, 2%); } }

  &--secondary {
    background: $secondary;
    &:hover {
      background: darken($secondary, 8%); }
    &:active {
      background: darken($secondary, 13%); }
    &:disabled {
      background: lighten($secondary, 15%);
      cursor: not-allowed;
      color: lighten($secondary, 2%); } }

  &--default {
    background: $white;
    border: 1px solid $grey-medium;
    color: $black;
    &:hover {
      color: $primary; }
    &:active {
      color: $primary;
      background: darken($white, 3%); }
    &:disabled {
      background: lighten($secondary, 15%);
      cursor: not-allowed;
      color: lighten($secondary, 2%); } }

  &--danger {
    background: $alert-danger;
    color: $white;
    &:hover {
      background: darken($alert-danger, 8%); }
    &:active {
      background: darken($alert-danger, 15%); }
    &:disabled {
      background: lighten($alert-danger, 15%);
      cursor: not-allowed;
      color: lighten($alert-danger, 2%); } }

  &__icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;

    i {
      font-size: 20px;
      position: absolute;
      left: 15px; }

    img {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 15px; } }

  &__loading {
    position: relative;
    top: 0;
    left: -4px;
    width: 30px;
    height: 20px;

    div {
      position: absolute;
      border: 2px solid #fff;
      opacity: 1;
      border-radius: 50%;
      animation: button__loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

      &:nth-child(2) {
        animation-delay: -0.5s; } }

    @keyframes button__loading {
      0% {
        top: 10px;
        left: 10px;
        width: 0;
        height: 0;
        opacity: 1; }

      100% {
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        opacity: 0; } } } }


.dark-mode-app {
  .button {
    &--primary {
      background: darken($primary, 15%);
      &:hover {
        background: darken($primary, 20%); }
      &:active {
        background: darken($primary, 24%); }
      &:disabled {
        background: lighten($primary, 10%);
        opacity: .8; } }

    &--secondary {
      background: $secondary;
      &:hover {
        background: darken($secondary, 15%); }
      &:active {
        background: darken($secondary, 20%); }
      &:disabled {
        background: lighten($secondary, 23%);
        cursor: not-allowed;
        color: lighten($secondary, 10%); } } } }

