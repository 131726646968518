@import "../../styles/colors";

.settings-page {
  width: 100%;

  &__create-wrapper {

    color: $grey-darker;
    margin-bottom: 14px;
    display: inline-flex;
    align-items: center;

    i {
      margin-right: 5px; }

    &:hover {
      cursor: pointer;
      color: $primary; } }

  &__create-pop-confirm {
    width: 500px;

    .anticon {
      display: none; }

    .ant-popover-message-title {
      padding-left: 0; } }

  &__pop-up-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between; }

  &__pop-up-user-creation-inputs {
    width: 50%;

    .input-wrapper {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0; } } }

  &__pop-up-user-creation-toggles {
    width: 40%;

    &_title {
      font-family: Roboto-medium, sans-serif;
      color: $grey-dark;
      border-bottom: 1px solid $grey-medium;
      margin-bottom: 10px; }

    .ant-switch {
      margin-left: 10px; } }

  .table-component {
    &__user-column {
      .ant-avatar {
        margin-right: 10px; } } }

  &__actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      border-radius: 50%;
      width: 23px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-bottom: 2px; }

      &:hover {
        cursor: pointer;
        background-color: $white;
        color: $primary;

        &:last-child {
          background-color: $alert-danger;
          color: $white; } } } }

  &__expanded-wrapper {
    padding: 10px 0; }

  &__user-info-wrapper {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 40px;
    display: flex;

    &_inputs {
      width: 300px;
      margin-right: 40px;
      .input-wrapper {
        margin-bottom: 5px; } }

    .settings-page__pop-up-user-creation-toggles {
      width: 300px;
      padding-right: 40px;

      .input-wrapper {
        margin-bottom: 5px;
        margin-top: 10px; } } } }




