@import '../../styles/colors.sass';

.toggle-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 55px;
  height: 23px;
  background-color: $grey-dark;
  border-radius: 50px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: $grey-darker; }

  &--active {
    background-color: $primary;

    &:hover {
      background-color: $primary-dark; }

    .toggle-button__circle {
      left: 35px; } }

  &--disabled {
    background-color: lighten($grey-darker, 34%);
    cursor: no-drop;

    .toggle-button__circle {
      box-shadow: none; }

    &:hover {
      background-color: lighten($grey-darker, 34%); } }


  &__circle {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: $white;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    box-shadow: 0 1px 3px 0 $grey-darkest;
    transition: 0.3s; }

  &__text {
    color: $white;
    font-size: 12px;
    font-family: Roboto-bold, sans-serif;
    padding-left: 40px;
    padding-right: 20px; } }

