@import "../../styles/colors";

.devices-page {
  max-width: 1200px;
  margin: 0 auto;
  .table-component {
    td {
      padding: 0 10px !important;
      height: 50px !important; }

    .ant-table-content {
      overflow: visible; }

    &__actions-wrapper {
      display: flex;
      justify-content: space-between; }

    .timezone-select {
      max-width: 400px; }

    .table-component {
      &__actions-wrapper {
        i {
          border-radius: 50%;
          width: 23px;
          height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-bottom: 2px; }

          &:hover {
            cursor: pointer;
            background-color: $white;
            color: $primary;

            &:last-child {
              background-color: $primary;
              color: $white; } } } } } } }


