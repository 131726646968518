@import "colors";

.ant-input {
  &:hover {
    border-color: $primary; }
  &:focus {
    border-color: $primary;
    box-shadow: 0 0 0 2px rgba(26, 188, 156, 0.2); } }

.ant-calendar-picker {
  .ant-input, .ant-calendar-range-picker-input {
    border-color: $grey-medium;
    outline: none; }
  &:hover {
    input, .ant-input {
      border-color: $grey-dark !important; }
    .ant-calendar-range-picker-input {
      box-shadow: none !important; } }

  &:focus {
    input, .ant-input {
      border-color: $primary !important;
      box-shadow: 0 0 0 2px rgba(26, 188, 156, 0.2) !important;
      outline: none; }
    .ant-calendar-range-picker-input {
      box-shadow: none !important; } }

  &:active {
    .ant-input, .ant-calendar-range-picker-input {
      border-color: $primary; } } }


.ant-calendar-table {
  .ant-calendar-date {
    &:hover {
      background: lighten($primary, 50%); } }

  .ant-calendar-today {
    .ant-calendar-date {
      border-color: $primary;
      color: $primary; } }

  .ant-calendar-selected-day {
    .ant-calendar-date {
      background-color: $primary;
      color: $white;
      border-color: $grey-medium-dark;
      box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3); } }

  .ant-calendar-in-range-cell {
    &:before {
      background-color: lighten($primary, 50%); } }

  .ant-calendar-selected-start-date, .ant-calendar-selected-end-date {
    .ant-calendar-date {
      &:hover {
        background: $primary; } } } }

.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    &:hover {
      background: lighten($primary, 50%); } } }

.ant-tabs-bar {
  font-family: Roboto-regular, sans-serif;
  .ant-tabs-tab-active {
    color: $primary; }

  .ant-tabs-tab {
    &:hover {
      color: $primary; } }

  .ant-tabs-ink-bar {
    background-color: $primary; } }

.ant-btn {
  i {
    color: white; }

  &:hover {
    color: $primary;
    border-color: darken($primary, 2%); }
  &:active {
    color: $primary;
    border-color: darken($primary, 2%); }
  &:disabled {
    opacity: 0.5;
    background: lighten($primary, 5%);
    border-color: darken($primary, 2%);
    cursor: not-allowed;
    color: lighten($primary, 2%);

    &:hover {
      background: lighten($primary, 5%);
      border-color: darken($primary, 2%);
      cursor: not-allowed;
      color: lighten($primary, 2%); } } }


.ant-btn-primary {
  background-color: $primary;
  border-color: darken($primary, 2%);

  i {
    color: $white; }

  &:hover {
    color: $white;
    background: darken($primary, 3%);
    border-color: darken($primary, 2%); }
  &:active {
    color: $white;
    background: darken($primary, 5%);
    border-color: darken($primary, 2%); }
  &:disabled {
    background: lighten($primary, 5%);
    border-color: darken($primary, 2%);
    cursor: not-allowed;
    color: lighten($primary, 2%); } }

.ant-switch-checked {
  background-color: $primary; }


.ant-transfer {
  .ant-transfer-list-content-item {
    &:hover {
      background-color: lighten($primary, 50%); } } }

.ant-checkbox-wrapper {
  &:hover {
    .ant-checkbox-inner {
      border-color: darken($primary, 3%); } } }

.ant-checkbox {
  .ant-checkbox-inner {
    &:hover {
      border-color: darken($primary, 3%); } } }

.ant-checkbox-checked {
  &:after {
    border: transparent; }
  .ant-checkbox-inner {
    background-color: $primary;
    border-color: darken($primary, 3%) !important; } }


.ant-dropdown {
  .ant-dropdown-menu-item {
    &:hover {
      background-color: lighten($primary, 50%); } }
  .ant-dropdown-menu-item-selected {
    background-color: lighten($primary, 55%);
    span {
      color: $primary; } }
  .ant-table-filter-dropdown-btns {
    .ant-table-filter-dropdown-link {
      cursor: pointer;
      color: $primary; } } }

