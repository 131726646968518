@import "src/styles/colors";

.analytics-page {
  max-width: 1200px;
  margin: 0 auto;

  &__info-wrapper {
    .table-component {
      .ant-pagination-item {
        border-radius: 0 !important;
        border-right: 0 !important; }

      .ant-pagination-next {
        border: 0 !important;

        .ant-pagination-item-link {
          border-radius: 0 4px 4px 0; } } } }


  &__header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_selectors-wrapper {
      display: flex; }

    .ant-calendar-picker {
      margin-right: 10px;
      .ant-calendar-picker-input {
        height: 38px; } }

    .select {
      width: 150px; } }

  &__overview-square-wrapper {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }

  &__overview-rectangle-wrapper {
    width: 100%;
    height: 190px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }

  &__overview-square-effect {
    background-color: $white;
    border-radius: 3px;
    display: flex;

    &:hover {
      box-shadow: 0 5px 6px 0 rgba(0,0,0,0.05);
      transform: translateY(-1px); } }

  &__overview-more-button {
    position: absolute;
    width: 28px;
    height: 28px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 10px;
    font-size: 20px;
    border-radius: 50%;
    outline: none;
    svg {
      color: $grey-dark; }

    &:hover {
      background-color: $grey-lightest;
      cursor: pointer; }

    &:focus {
      background-color: $grey-lightest; } }

  &__overview-square {
    position: relative;
    width: 23.5%;
    height: 100%;
    @extend .analytics-page__overview-square-effect;
    padding: 20px;

    &_wrapper {
      width: 100%; }

    &_icon {
      text-align: center;
      margin-top: 20px;

      i {
        height: 45px;
        width: 45px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        border-radius: 50%; } }

    &_number {
      text-align: center;
      margin: 20px 0;
      font-family: Roboto-bold, sans-serif;
      font-size: 42px; }

    &_description {
      text-align: center;
      font-family: Roboto-light, sans-serif;
      color: $grey-dark; }

    &:nth-child(1) {
      .analytics-page__overview-square_icon {
        i {
          color: $primary;
          background: lighten($primary, 50%); } } }

    &:nth-child(2) {
      .analytics-page__overview-square_icon {
        i {
          color: $secondary;
          background: lighten($secondary, 40%); } } }

    &:nth-child(3) {
      .analytics-page__overview-square_icon {
        i {
          color: $alert-warning;
          background: lighten($alert-warning, 38%); } } }

    &:nth-child(4) {
      .analytics-page__overview-square_icon {
        i {
          color: $alert-danger;
          background: lighten($alert-danger, 32%); } } } }



  &__overview-rectangle {
    position: relative;
    width: 32%;
    height: 100%;
    @extend .analytics-page__overview-square-effect;
    padding: 20px;

    &_wrapper {
      width: 100%; }

    &_header {
      font-family: Roboto-bold, sans-serif;
      font-size: 18px;
      margin-bottom: 20px; }

    &_content {
      &--row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $grey-border;
        margin-bottom: 10px;

        &:last-child {
          border-bottom: none;
          margin-bottom: 0; } }

      &--country {
        width: 90%;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }

      &--number {
        margin-bottom: 10px;
        color: $grey-dark; }

      &--no-data {
        padding-top: 10px;
        .ant-empty {
          margin: 0; } } } }

  &__chart-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0; } }

  &__chart {
    position: relative;
    width: 49%;
    @extend .analytics-page__overview-square-effect;
    padding: 20px;
    margin-bottom: 2%; }

  &__chart-content {
    width: 100%; }

  &__chart-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select {
      width: 150px; } }

  &__chart-header {
    font-family: Roboto-bold, sans-serif;
    font-size: 18px; }

  &__chart-total {
    &_description {
      font-family: Roboto-light, sans-serif;
      color: $grey-dark; }
    &_number {
      margin-left: 10px;
      font-family: Roboto-bold, sans-serif;
      color: $primary; } }

  &__chart-doughnut {
    margin: 30px auto 10px auto;
    position: relative;
    width: 100%; }


  &__chart-line {
    position: relative;
    width: 100%;
    padding: 20px;
    background-color: $white;
    border-radius: 3px;
    margin-bottom: 20px;

    &:hover {
      box-shadow: 0 5px 6px 0 rgba(0,0,0,0.05);
      transform: translateY(-1px); }

    &_header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between; }

    &_header {
      font-family: Roboto-bold, sans-serif;
      font-size: 18px;
      margin-bottom: 20px; }

    &_selectors-wrapper {
      display: flex;
      margin-right: 30px;

      .select {
        width: 150px; }

      .ant-calendar-picker {
        margin-right: 10px; }
      .ant-calendar-picker-input, .ant-time-picker-input {
        height: 38px; } }

    &_time-wrapper {
      display: flex;
      margin-right: 10px;
      align-items: center;
      padding-bottom: 10px; }

    &_time-wrapper-separator {
      margin: 0 10px; } }


  &__tabs {
    &--hide {
      .ant-tabs-bar {
        display: none; } } } }


// DARK MODE SETUP
.dark-mode-app {
  .analytics-page__overview-more-button {
    &:hover, &:active, &:focus {
      background-color: darken($black-mode-light-black, 5%); } }
  // SQUARE COMPONENT
  .analytics-page__overview-square {
    background-color: $black-mode-black;

    &:hover {
      box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.49); }

    &:nth-child(1) {
      .analytics-page__overview-square_icon {
        i {
          color: $primary;
          background: lighten($black-mode-light-black, 10%); } } }

    &:nth-child(2) {
      .analytics-page__overview-square_icon {
        i {
          color: $secondary;
          background: lighten($black-mode-light-black, 10%); } } }

    &:nth-child(3) {
      .analytics-page__overview-square_icon {
        i {
          color: $alert-warning;
          background: lighten($black-mode-light-black, 10%); } } }

    &:nth-child(4) {
      .analytics-page__overview-square_icon {
        i {
          color: $alert-danger;
          background: lighten($black-mode-light-black, 10%); } } }

    &_number {
      color: $black-mode-white; } }

  // RECTANGLE COMPONENT
  .analytics-page__overview-rectangle {
    background-color: $black-mode-black;

    &:hover {
      box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.49); }

    &_header {
      color: $black-mode-white; }

    &_content {
      color: $black-mode-white;

      &--row {
        border-color: $black-mode-lighter-black; } }

    .ant-empty-description {
      color: $black-mode-white; } }


  // CHART COMPONENT
  .analytics-page__chart {
    background-color: $black-mode-black;

    &:hover {
      box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.49); } }

  .analytics-page__chart-header {
    color: $black-mode-white; }


  // LINE CHART COMPONENT
  .analytics-page__chart-line {
    background-color: $black-mode-black;

    &:hover {
      box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.49); }

    &_header {
      color: $black-mode-white; } } }




