@import '../../styles/colors.sass';

.input-wrapper {
  min-width: 150px; }

.input-label {
  color: $black;
  font-family: Roboto-medium, sans-serif;
  font-size: 14px;
  margin-bottom: 5px; }

.input {
  font-family: Roboto-regular, sans-serif;
  font-size: 15px;
  height: 32px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $grey-medium;
  border-radius: 3px;
  padding: 0 10px;
  outline: none;
  color: $grey-darkest;

  &:hover {
    border: 1px solid $grey-dark; }

  &:focus {
    border: 1px solid $primary; }

  &::placeholder {
    opacity: 0.5; }

  &:disabled {
    background: $grey-pre-medium;
    cursor: not-allowed;
    color: $grey-dark;
    &:hover {
      border: 1px solid $grey-medium; } }

  &--error {
    border: 1px solid $alert-danger !important; } }

.textarea {
  @extend .input;
  padding: 5px 10px;
  resize: vertical;
  min-height: 47px;
  max-height: 150px;

  &--error {
    border: 1px solid $alert-danger !important; } }

.input-error-message {
  font-family: Roboto-medium, sans-serif;
  color: $alert-danger;
  font-size: 14px; }

.dark-mode-app {
  input {
    background: $black-mode-light-black;
    border-color: $black-mode-lighter-black;
    color: $black-mode-white;

    &:hover {
      border: 1px solid $grey-dark; }

    &:focus {
      border: 1px solid darken($primary, 10%); } } }

