@import '../../styles/colors.sass';

.nav-bar-left {
  width: 70px;
  min-width: 70px;
  background-color: $white;
  color: $black;
  border-right: 2px solid $grey-lightest;
  transition: 0.3s ease-in-out width;

  &.--expand {
    width: 210px;
    min-width: 210px;
    transition: 0.3s ease-in-out;

    .nav-bar-left__wrapper {
      .nav-bar-left__item {
        .nav-bar-text {
          opacity: 1; } } } }



  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 18px 0;

    &--active {
      background: linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(251,251,251,1) 50%, rgba(255,255,255,1) 100%); } }

  &__item {
    position: relative;
    width: 100%;
    font-size: 20px;
    display: flex;
    justify-content: initial;
    align-items: center;
    padding: 16px 0 16px 25px;
    color: $grey-darker;
    cursor: pointer;
    outline: none;
    opacity: 1;

    svg {
      color: $grey-darker;
      transition: 0.3s ease-in-out; }

    .nav-bar-text {
      position: absolute;
      left: 55px;
      font-size: 17px;
      font-weight: 400;
      opacity: 0;
      transition: 0.3s; }

    &_bar {
      position: absolute;
      width: 4px;
      height: 50px;
      left: 0;
      background-color: $primary;
      opacity: 0;
      transition: 0.3s ease-in-out; }

    &.--active {
      background: linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(251,251,251,1) 50%, rgba(255,255,255,1) 100%);
      svg {
        color: $primary; }

      .nav-bar-left__item_bar {
        opacity: 1; }

      .nav-bar-text {
        color: $primary; } }

    &:hover {
      svg {
        color: $primary; }
      .nav-bar-left__item_power-off {
        svg {
          color: $alert-danger !important; } }
      .nav-bar-text--red {
        color: $alert-danger; } } } }
