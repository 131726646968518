@import '../../styles/colors.sass';

.select {
  font-family: Roboto-regular, sans-serif;
  font-size: 15px;

  .css-yk16xz-control {
    min-height: 32px;
    border: 1px solid $grey-medium;
    border-radius: 3px;

    &:hover {
      border: 1px solid $grey-dark; } }

  .css-1pahdxg-control {
    min-height: 32px;
    border: 1px solid $primary;
    box-shadow: none;

    &:hover {
      border: 1px solid $primary;
      box-shadow: none; } }

  .css-1fhf3k1-control {
    height: 32px;
    min-height: 32px;
    border: 1px solid $grey-medium;
    background-color: $grey-pre-medium;
    border-radius: 3px;
    cursor: not-allowed;
    pointer-events: initial;

    .css-1wa3eu0-placeholder {
      color: $grey-dark; }

    &:hover {
      border: 1px solid $grey-medium; } }

  .css-1ml51p6-MenuList {
    font-family: Roboto-regular, sans-serif;
    font-size: 15px; }

  .css-1n7v3ny-option {
    background-color: lighten($primary, 50%) !important; }

  .css-9gakcf-option {
    background-color: $primary !important; }

  .css-1rhbuit-multiValue {
    background-color: lighten($primary, 50%); }

  .css-1alnv5e {
    &:hover {
      background-color: $alert-danger;
      color: $white; } } }


// DARK MODE SETUP
.dark-mode-app {
  .select {
    .css-yk16xz-control, .css-1pahdxg-control {
      background-color: $black-mode-light-black;
      border-color: $black-mode-lighter-black; }

    .css-1uccc91-singleValue {
      color: $black-mode-white; }

    .css-26l3qy-menu {
      background-color: $black-mode-light-black;
      color: $black-mode-white;

      // selected
      .css-9gakcf-option {
        background-color: darken($primary, 15%) !important; }

      // hover
      .css-1n7v3ny-option {
        background-color: darken($black-mode-light-black, 5%) !important; } } } }
