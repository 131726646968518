@import './styles/colors.sass';

.dark-mode-app {
  background: $black-mode-true-black;
  color: $black-mode-white;
  h2 {
    color: $black-mode-white; }
  h3 {
    color: $black-mode-white; }

  .route-container {
    background-color: $black-mode-true-black; }

  // NAV BAR Component
  .nav-bar-left {
    background-color: $black-mode-black;
    border-right: 2px solid $black-mode-black;

    &__item {
      &.--active {
        background: linear-gradient(90deg, #000000 0%, #111113 50%, #171617 100%); } } }

  // LEFT BAR Component
  .nav-bar-top {
    background: $black-mode-black;
    border-bottom: 2px solid $black-mode-light-black; }

  // TABLE SETUP
  .ant-table-row-expand-icon-cell {
    .ant-table-row-expand-icon {
      background: $black-mode-light-black; } }

  .ant-table-expanded-row {
    background: $black-mode-lighter-black;

    &:hover {
      background: $black-mode-lighter-black; } }

  .ant-spin-container {
    &:after {
      background: transparent; } }
  .ant-table-placeholder {
    background-color: $black-mode-light-black;
    color: $black-mode-white;

    .ant-empty {
      .ant-empty-description {
        color: $black-mode-white; } } }

  .ant-table-content {
    border: 1px solid $black-mode-black !important; }
  .ant-table {
    table {
      background: $black-mode-light-black !important;

      .ant-table-row {
        &:hover {
          td {
            background: darken($black-mode-light-black, 5%) !important; } } }

      td {
        color: $black-mode-white !important;
        border-bottom: 1px solid $black-mode-lighter-black; }

      .ant-table-thead {
        tr {
          th {
            border-bottom: 1px solid $black-mode-light-black;
            background-color: darken($primary, 15%) !important;

            &:hover {
              background-color: darken($primary, 10%) !important; } }

          .ant-table-column-has-actions, .ant-table-column-has-filters, .ant-table-column-has-sorters, .ant-table-expand-icon-th, .ant-table-row-cell-break-word {
            border-bottom: 1px solid $black-mode-light-black;
            background-color: darken($primary, 15%) !important;

            &:hover {
              background-color: darken($primary, 10%) !important; } }

          .anticon-search, .anticon-filter {
            background-color: darken($primary, 15%) !important;
            &:hover {
              background-color: darken($primary, 5%) !important; } } } } } }

  .ant-pagination {
    li, a {
      background: $black-mode-light-black !important;
      color: $black-mode-white;
      transition: none;

      &:hover {
        a {
          background: $black-mode-lighter-black !important;
          box-shadow: 0 5px 4px -3px rgba(0, 0, 0, 0.3) !important; } } }

    .ant-pagination-options {
      background-color: transparent !important; }

    .ant-pagination-item-active {
      a {
        background: $black-mode-lighter-black !important;
        box-shadow: 0 5px 4px -3px rgba(0, 0, 0, 0.3) !important; } }

    .ant-pagination-prev, .ant-pagination-item, .ant-pagination-jump-next, .ant-pagination-jump-prev, .ant-pagination-jump-next-jump, .ant-pagination-next {
      border: 1px solid $black-mode-lighter-black !important; } }

  // ANT SELECT
  .ant-select-selection {
    background-color: $black-mode-light-black;
    color: $black-mode-white;
    border: 1px solid $black-mode-lighter-black !important;
    transition: none;

    .ant-select-arrow {
      color: $black-mode-white; }

    &:active, &:focus {
      box-shadow: 0 0 0 2px rgba(104, 101, 110, 0.2) !important; } }

  .ant-select-dropdown-menu {
    li {
      background-color: $black-mode-light-black !important;

      &:hover {
        background-color: $black-mode-lighter-black !important; } }

    .ant-select-dropdown-menu-item-selected {
      background-color: $black-mode-lighter-black !important; } }

  // ANT DROPDOWN
  .ant-dropdown {
    .ant-table-filter-dropdown {
      background-color: $black-mode-black; }
    span {
      color: $black-mode-white; } }


  // ANT INPUT
  .ant-input {
    background-color: $black-mode-light-black;
    border: 1px solid $black-mode-lighter-black;
    color: $black-mode-white; }

  // ANT BUTTON
  .ant-btn {
    background-color: $black-mode-light-black;
    color: $black-mode-white;
    border: 1px solid $black-mode-lighter-black;

    &:hover {
      background-color: $black-mode-light-black;
      border: 1px solid darken($primary, 15%);
      color: darken($primary, 5%); } }

  .ant-btn-primary {
    background-color: darken($primary, 15%);
    border: 1px solid darken($primary, 10%);
    color: $black-mode-white;

    &:hover {
      background-color: darken($primary, 20%);
      border: 1px solid darken($primary, 15%);
      color: $black-mode-white; } }

  // CHECKBOX
  .toggle-button {
    background-color: $black-mode-lighter-black; }
  .toggle-button--active {
    background-color: darken($primary, 15%); }

  // ANT CHECKBOX
  .ant-checkbox {
    .ant-checkbox-inner {
      background-color: $black-mode-light-black;
      border-color: $black-mode-lighter-black; }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: darken($primary, 20%);
        border: darken($primary, 15%); } } }


  // CALENDAR PICKER
  .ant-calendar-picker {
    .ant-calendar-picker-input {
      border: 1px solid $black-mode-lighter-black; }
    .ant-calendar-range-picker-input {
      color: $black-mode-white; }

    .anticon {
      color: $black-mode-white; }

    &:hover {
      .anticon {
        color: darken($primary, 5%);
        background-color: $black-mode-light-black; } } }

  // TAB BAR
  .ant-tabs {
    color: $black-mode-white;
    .ant-tabs-bar {
      border-bottom: 1px solid $black-mode-lighter-black;

      .ant-tabs-ink-bar {
        background-color: darken($primary, 15%); }

      .ant-tabs-tab {
        color: $black-mode-white; }

      .ant-tabs-tab-active {
        color: darken($primary, 10%); } } }

  // ANT_DROPDOWN
  .ant-dropdown {
    .ant-dropdown-menu {
      background-color: $black-mode-lighter-black;

      .ant-dropdown-menu-item {
        color: $black-mode-white;

        &:hover,
        &.ant-dropdown-menu-item-selected {
          background-color: darken($black-mode-lighter-black, 5%); } } } } }

.dark-mode-app {
  .rangePicker-dark-mode, .datePicker-dark-mode, .timeRange-dark-mode {
    .ant-calendar, .ant-time-picker-panel-inner {
      background-color: $black-mode-black !important;
      border: 1px solid $black-mode-lighter-black;
      color: $black-mode-white;

      .ant-calendar-input-wrap {
        border-bottom: 1px solid $black-mode-lighter-black; }

      .ant-calendar-input {
        background-color: $black-mode-light-black;
        border-radius: 4px;
        padding-left: 5px;
        color: $black-mode-white; }

      .ant-calendar-header {
        .ant-calendar-prev-year-btn, .ant-calendar-prev-month-btn, .ant-calendar-next-month-btn, .ant-calendar-next-year-btn {
          color: $black-mode-white;

          &:hover {
            &:before, &:after {
              border-color: darken($primary, 10%); } } }

        .ant-calendar-my-select {
          .ant-calendar-month-select, .ant-calendar-year-select {
            color: $black-mode-white;

            &:hover {
              color: darken($primary, 10%); } } } }

      .ant-calendar-body {
        .ant-calendar-tbody {
          .ant-calendar-date {
            color: $black-mode-white !important; }

          .ant-calendar-today {
            .ant-calendar-date {
              color: darken($primary, 10%) !important;
              border-color: darken($primary, 20%); } }

          .ant-calendar-selected-day {
            .ant-calendar-date {
              color: $black-mode-white !important;
              background-color: darken($primary, 15%);
              border-color: darken($primary, 5%); } }

          .ant-calendar-date {
            &:hover {
              color: $black-mode-white !important;
              background-color: darken($primary, 15%);
              border-color: darken($primary, 5%); } } } }

      .ant-calendar-month-panel, .ant-calendar-year-panel, .ant-calendar-decade-panel {
        background-color: $black-mode-black !important;
        .ant-calendar-month-panel-header, .ant-calendar-year-panel-header, .ant-calendar-decade-panel-header {
          border-color: $black-mode-lighter-black;
          .ant-calendar-month-panel-prev-year-btn,
          .ant-calendar-month-panel-next-year-btn,
          .ant-calendar-year-panel-prev-decade-btn,
          .ant-calendar-year-panel-next-decade-btn,
          .ant-calendar-decade-panel-prev-century-btn,
          .ant-calendar-decade-panel-next-century-btn {
            color: $black-mode-white;

            &:hover {
              &:before, &:after {
                border-color: darken($primary, 10%); } } }

          .ant-calendar-month-panel-year-select,
          .ant-calendar-year-panel-decade-select {
            .ant-calendar-month-panel-year-select-content,
            .ant-calendar-year-panel-decade-select-content {
              color: $black-mode-white;

              &:hover {
                color: darken($primary, 10%); } } } }

        .ant-calendar-month-panel-body,
        .ant-calendar-year-panel-body,
        .ant-calendar-decade-panel-body {
          .ant-calendar-month-panel-month,
          .ant-calendar-year-panel-year,
          .ant-calendar-decade-panel-decade {
            color: $black-mode-white; }

          .ant-calendar-month-panel-selected-cell,
          .ant-calendar-year-panel-selected-cell,
          .ant-calendar-decade-panel-selected-cell {
            .ant-calendar-month-panel-month,
            .ant-calendar-year-panel-year,
            .ant-calendar-decade-panel-decade {
              color: $black-mode-white !important;
              background-color: darken($primary, 15%);
              border-color: darken($primary, 5%); } }

          .ant-calendar-month-panel-current-cell, {
            .ant-calendar-month-panel-month,
            .ant-calendar-year-panel-year {
              color: darken($primary, 10%);
              border: 1px solid  darken($primary, 20%); } }

          .ant-calendar-month-panel-month,
          .ant-calendar-year-panel-year,
          .ant-calendar-decade-panel-decade {
            &:hover {
              color: $black-mode-white !important;
              background-color: darken($primary, 15%);
              border: 1px solid darken($primary, 5%); } } } }

      .ant-calendar-in-range-cell {
        &:before {
          background-color: darken($primary, 28%); } } }

    .ant-time-picker-panel-select {
      .ant-time-picker-panel-select-option-selected {
        background-color: darken($primary, 15%);
        color: $white; }

      li {
        &:hover, &:active, &:focus {
          background-color: darken($primary, 10%);
          color: $white; } } } }

  .ant-time-picker {
    .anticon {
      color: $primary;
      background: transparent;
      outline: none; }
    .ant-time-picker-icon {
      .anticon {
        color: $white !important; } }

    &:hover {
      .ant-time-picker-icon {
        opacity: 0; } } }




  // POP-CONFIRM
  .popover-component-dark-mode {
    .ant-popover-title {
      color: $black-mode-white !important;
      border-bottom: 1px solid $black-mode-lighter-black; }
    .ant-popover-arrow {
      border-top-color: $black-mode-black !important;
      border-left-color: $black-mode-black !important; }
    .ant-popover-inner {
      background-color: $black-mode-black !important; }

    .ant-popover-inner-content {
      .ant-transfer-list {
        border: 1px solid $black-mode-lighter-black;

        .ant-transfer-list-header {
          background: $black-mode-lighter-black;
          color: $black-mode-white;
          border-bottom: 1px solid $black-mode-black; }

        .ant-transfer-list-body {
          color: $black-mode-white; }

        .ant-transfer-list-content-item {
          &:hover, &:focus, &:active {
            background-color: $black-mode-light-black; } } }

      .settings-page__pop-up-user-creation-toggles {
        span {
          color: $black-mode-white; } } } } }




