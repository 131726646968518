@import '../node_modules/rc-calendar/dist/rc-calendar.css';
@import '../node_modules/react-notifications-component/dist/theme.css';

@import './styles/normalize.css';
@import './styles/robotoFonts.css';
@import './styles/colors.sass';
@import './styles/customTableStyle.sass';
@import './styles/antd.css';
@import './styles/antdTheme';
@import './styles/animate.css';

body {
  font-family: Roboto-regular, sans-serif;
  font-size: 16px;
  color: $black;
  min-width: 1024px;
  overflow-x: hidden;
  background-color: $grey-lightest;

  h2, h3, h4 {
    font-family: Roboto-bold, sans-serif;
    color: $grey-darkest; }

  h2 {
    font-size: 26px; }

  h3 {
    font-size: 22px; }

  h4 {
    font-size: 18px; }

  a {
    text-decoration: none; } }
