@import "colors";

.table-component {
  margin-bottom: 65px;

  .ant-table-title {
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .table-component__title {
      background: transparent;
      border-bottom: none;
      font-size: 16px;
      color: $grey-darker;
      font-family: Roboto-bold, sans-serif; }

    i {
      @extend .table-component__title;
      margin-left: 15px;
      outline: none;

      &:hover {
        color: $primary; } } }

  .ant-table-content {
    border: 1px solid $grey-medium-dark;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15); }

  .ant-table-column-has-filters,
  .ant-table-column-has-sorters {
    .anticon-search, .anticon-filter {
      background: $primary !important;
      color: $white;
      outline: none;
      box-shadow: none;

      &:hover {
        background: darken($primary, 1.5%) !important;
        color: $white; } }

    .ant-dropdown-open {
      color: $grey-dark !important;
      background: darken($primary, 1.5%) !important; } }


  .ant-table {
    .ant-table-body {
      margin: 0 0;
      table {
        background: $white;
        table-layout: auto; }

      thead {
        th {
          background: $primary !important;
          color: white; } }

      tr {
        td {
          padding: 8px 16px; }

        &:hover {
          td {
            background: lighten($primary, 50%); } } } } }


  // PAGINATION STYLING
  .ant-pagination {
    position: absolute;
    right: 0;

    li, a {
      transition: none; }

    .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next, .mini {
      background-color: $white;
      margin: 0;
      border-radius: 0;
      border: 1px solid $grey-medium;
      border-right: none;
      overflow: hidden;

      &:first-child {
        border-radius: 5px 0 0 5px;
        a {
          border: none; } }

      &:nth-last-child(2) {
        border-right: 1px solid $grey-medium;
        border-radius: 0 5px 5px 0;
        a {
          border: none; } }

      a {
        border-radius: 0; } }

    .ant-pagination-item {
      &:hover {
        @extend .ant-pagination-item-active;
        a {
          opacity: 0.4; } } }

    .ant-pagination-prev, .ant-pagination-next {
      &:hover {
        i {
          color: $primary; } } }

    .ant-pagination-jump-prev, .ant-pagination-jump-next {
      .ant-pagination-item-container {
        line-height: 28px;
        i {
          color: $primary; } }
      .ant-pagination-item-ellipsis {
        line-height: 32px; } }



    &:first-child {
      li {
        border-radius: 0 0 5px 5px !important; } }

    .ant-pagination-item-active {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: $grey-pre-medium;
      border: 1px solid $grey-medium;
      border-right: none;

      a {
        background: $primary;
        width: 80%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        border-radius: 4px;
        box-shadow: 0 5px 4px -3px rgba(0, 0, 0, 0.3); } }

    .ant-pagination-options {
      height: 34px;

      .ant-select-selection {
        height: 34px;
        display: flex;
        align-items: center;
        transition: none;

        &:hover, &:active, &:focus {
          border-color: $primary; } } }

    .ant-select-dropdown {
      transition: none;
      .ant-select-dropdown-menu-item {
        &:hover {
          background: lighten($primary, 50%); } } } }


  .ant-table-column-sorter {
    i {
      color: $white; }
    .on {
      color: lighten($primary, 20%) !important; } }

  .ant-transfer-list-style-type {
    height: 230px; }

  &__other-wrapper {
    display: flex;
    justify-content: flex-end; }



  &__download-wrapper {
    margin-left: 10px;
    color: $grey-darker;
    height: 38px;

    i {
      margin-left: 0 !important; }

    &:active, &:focus, &:visited {
      cursor: pointer;
      color: $primary; }

    &:hover {
      cursor: pointer;
      color: $primary;
      border-color: transparent !important;

      i {
        color: $primary; } } }

  &__select {
    width: 200px;
    z-index: 100; }

  .ant-table-row-expand-icon {
    &:hover, &:active, &:focus {
      color: $primary !important; } }

  .ant-table-expanded-row {
    &:hover {
      td {
        background: initial !important; } } } }

