@import '../../styles/colors.sass';

.tooltip {
  display: inline-block;
  user-select: none;
  width: 100%;

  &_component {
    max-width: 300px;
    background-color: $grey-darkest !important;
    background-image: linear-gradient(147deg, $grey-darkest 0%, $black 74%);
    padding: 8px 14px !important;
    font-family: Roboto-bold, sans-serif;
    box-shadow: 0 4px 7px 0 rgba(0,0,0,0.3);
    opacity: 1; } }
