@font-face {
  font-family: 'Roboto-black';
  src: url('../assets/fonts/Roboto-Black-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-black-italic';
  src: url('../assets/fonts/Roboto-BlackItalic-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-bold';
  src: url('../assets/fonts/Roboto-Bold-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-bold-italic';
  src: url('../assets/fonts/Roboto-BoldItalic-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-medium';
  src: url('../assets/fonts/Roboto-Medium-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-medium-italic';
  src: url('../assets/fonts/Roboto-MediumItalic-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-regular';
  src: url('../assets/fonts/Roboto-Regular-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-italic';
  src: url('../assets/fonts/Roboto-Italic-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-light';
  src: url('../assets/fonts/Roboto-Light-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-light-italic';
  src: url('../assets/fonts/Roboto-LightItalic-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-thin';
  src: url('../assets/fonts/Roboto-Thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-thin-italic';
  src: url('../assets/fonts/Roboto-ThinItalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
